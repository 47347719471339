#about {
  min-width: 610px;
  margin-top:-18px;
}

#about-content h2 {
  color: #0404d9;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}
#about-content h3 {
  color: #e80179;
  font-size: 18px;
  font-weight: 600;
}

#about-content p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
#about {
  z-index: 1;
}
.center-vertical {
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}

@media (max-width: 1020px) {
  #about-content h2 {
    font-size: 24px;
    line-height: 30px;
  }

  #about-content p {
    font-size: 18px;
  }
  .center-vertical {
    /* left:0; */
  }
}
@media (max-width: 800px) {
  #about-content p {
    font-size: 14px;
  } 
}
@media (max-width: 767px) {
  #about{
    margin-top: 0;
    min-width: 0;
  }
  .center-vertical{
    transform: translateY(0);
    top:80px;
  }
  #about-content{
     padding-bottom: 108px;
  }
  #about-content p {
    font-size: 16px;
  } 
  #about-content{
    padding-right: 28px;
    padding-left: 28px;
  }
}
