#partnership {
  background: url("/public/assets/images/bg-partner.png") no-repeat 0 0;
  padding-top: 70px;
  background-position: top;
  padding-bottom: 180px;
  background-size: cover;
}

#partnership h2 {
  color: #0404d9;
  font-size: 40px;
  font-weight: 700;
}
#partnership h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
#partnership .logo-slim {
  width: 60px;
  margin:auto
}
#partnership .logo-fat {
  width: 166px;
  margin:auto
}
/* #partnership ul li {
  border: 1px solid yellow;
} */
@media (max-width: 767px) {
  #partnership h2 {
    font-size: 24px;
  }
  #partnership {
    padding-bottom: 70px;
  }
  #partnership ul li {
    display: block;
    position: relative;
    width: 100%;
  }
  
}

