#presenters-cont h2 {
  color: #0404d9;
  font-size: 40px;
  font-weight: 700;
}
#presenters-cont h3 {
  color: #e80179;
  font-size: 18px;
  font-weight: 600;
}
#presenters-cont .coachesName {
  color: #e80179;
  font-size: 20px;
  font-weight: 700;
}
.name-instructor {
  width: max-content;
}
.presenters-img {
  max-width: 230px;
}

/* mobile display */
@media (max-width: 1020px) {
  .presenters-img {
    max-width: 180px;
  } 
}
@media (max-width: 767px) {
  .presenters-img {
    max-width: 140px;
  }
  #presenters-cont .coachesName {
    font-size: 16px;
  }
  #presenters-cont h2 {
    font-size: 24px;
  }  

}
