/* ~~ Top Navigation Bar ~~ */

.pink-bg {
  background: url("/public/assets/images/bg-home.png") no-repeat 0 0;
  padding-bottom: 30px;
  background-position: bottom;
  background-size: cover;
  z-index: 10;
}

header img {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}
#navigation-container {
  /* background-color: yellowgreen; */
  width: 1200px;
  margin: 0 auto;
  height: auto;
  padding: 24px;
}

.navigation-bar {
  height: 160px;
  width: 100%;
  text-align: center;
}
.navigation-bar img {
  float: left;
}
.navigation-bar ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateY(-50%);
}

.navigation-bar li {
  list-style-type: none;
  padding: 0px;
  height: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: inline;
}

.navigation-bar li a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  line-height: 70px;
  padding: 5px 15px;
  font-size: 18px;
  font-weight: 500;
  vertical-align: middle;
}

#menu {
  float: right;
}

.btn {
  font-size: 18px;
  padding: 10px 24px;
  border: 0;
  border-radius: 50px;
  font-weight: 800;
  color: #fff;
}
.green-btn {
  float: right;
  background-color: #49bf00;
  /* position: absolute; */
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

.stickyHeader {
  background-color: #0404d9;
  width: 100%;
}
.stickyHeader li a {
  color: #e80179;
}
.desktop-nav {
  display: block;
}
.mobile-nav {
  display: none;
}
@media (max-width: 767px) {
  .desktop-nav {
    display: none;
  }
  .mobile-nav {
    /* background-color: #e80179; */
    display: block;
  }
  #navigation-container {
    padding: 0;
  }
  .pink-bg {
    padding-bottom: 0;
  }
  .pady-48 {
    padding-top: 0;
  }
}
#mobile-navi {
  display: none;
}
@media (max-width: 1300px) {
  .navigation-bar li a {
    font-size: 12px;
  }
}

