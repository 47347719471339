.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  /* background-color: #0404d9; */
  z-index: 100;
  position: fixed;
  /* top: 20vh;
  left: calc(30% - 15rem); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  z-index: 100;
}

#openModal h4 {
  font-size: 20px;
  font-weight: 700;
}
.title-instructor {
  color: #e80179;
}
.title-studio {
  color: #fff;
}
#openModal p {
  font-size: 16px;
  color: #fff;
  line-height: 19.5px;
}

#openModal .set-center {
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

#openModal .set-center {
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

#openModal .btn-close {
  width: 30px;
}

#openModal p {
  font-size: 16px;
  line-height: 20px;
}

.studio-bg {
  background-color: #e80179;
}
.instuctor-bg {
  background-color: #0404d9;
}

.pink-btn {
  color: #fff;
  background-color: #e80179;
}
.blue-btn {
  color: #fff;
  background-color: #0404d9;
}
/* mobile display */
@media (max-width: 767px) {
  #openModal p {
    font-size: 14px;
    line-height: 20px;
  }
  /* .modal{
    max-height:100vh
  } */
  #openModal .icon {
    max-width: 150px;
  }
  .h-scrren{
    height:400px;
  }
}
