.blue-btn {
  background-color: #0404d9;
}
#banner h1 {
  font-weight: 700;
  font-size: 64px;
  color: #0404d9;
  line-height: 70px;
}
#banner p {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
}
#banner-details {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  right: 0;
  /* margin-left: 48px; */
}

.show-desktop {
  display: block;
}
@media (max-width: 1300px) {
  .show-desktop {
    display: none;
  }
}
@media (max-width: 1300px) {
  #banner h1 {
    font-size: 32px;
  }
  #banner p {
    font-size: 16px;
  }
  .only-desktop {
    display: none;
  }
}
/* mobile display */
@media (max-width: 767px) {
  #banner-details {
    transform: translateY(0);
  }

  .blue-btn {
    width: max-content;
    margin-bottom: 30px;
    transform: translateY(50);
    font-size: 16px;
  }
  #banner img {
    margin-bottom: -30px;
  }
  #banner h1 {
    line-height: 32px;
    font-size: 28px;
  }
}
