/* sidebar Nav */
@media (max-width: 1023px) {
  .navigation-bar {
    display: none;
  }
  .mobile-navi{
    height: 84px;
  }
  .navBar {
    position: relative;
  }

  .navBar .menu {
    position: absolute;
    left: 15px;
    top: 20px;
    z-index: 10;
    cursor: pointer;
  }

  .menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 0;
    background: #0404d9;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    overflow: hidden;
    max-width: auto;
    z-index: 9;
  }

  .menuNav.showMenu {
    width: 100%;
  }

  .menuNav a {
    display: block;
    padding: 10px 40px;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }

  .menuNav li:first-child {
    margin-top: 2rem;
  }
  #mobile-navi {
    display: block;
    /* position: fixed; */
  }
  #mobile-navi .green-btn {
    right: 15px;
    top: 40px; 
    font-size:8px;   
    font-weight: 800;
  }
  #mobile-navi .logo {
    position: relative;
    height: 50px;
    top:15px;
    margin: auto;
  }
}