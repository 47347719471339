


.App {
  font-family: 'Montserrat',"Calibri",sans-serif;
  text-align: left;
  position: relative;
  height: 100vh;  
}
* {
  scroll-behavior: smooth;
}
.img{
  max-width: 100%;
}
html {
  background-color: #fff;
}


.container{
  margin:auto;
  padding-left: 28px;
  padding-right: 28px;
}
@media (min-width: 768px) {
  .container{
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1200px) {
  .container{
    padding-left: 48px;
    padding-right: 48px;
  }
}



.backdrop {
  position: fixed;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}


.pady-48 {
  padding-top:48px;
  padding-bottom:48px
}

/* div{
  border:1px solid
} */