

#programmes {
  margin-top:-48px;
  background: url("/public/assets/images/bg-blue.png") no-repeat 0 0;
  padding-top:120px;
  padding-bottom:70px;
  background-position: top;
  color: #fff;
  overflow: hidden;
  background-size: cover;
  z-index: 18;
}
#programmes.onModal {
  z-index: 96 !important;
}
#programmes h2 {
  color: #e80179;
  font-size: 40px;
  font-weight: 700;
}

#programmes .title {
  font-weight: 600;
}

#programmes .details {
  font-weight: 400;
  font-size: 32px;
}
.event-time {
  font-weight: 700;
  font-size: 26px;
  color: #e80179;
  font-style: normal;
  width:180px;
}
.event-details {
  font-weight: 500;
  font-size: 26px;
  max-width:  460px;
}
 #event-date {
  width: 130px;
  height: 130px;
}
#programmes strong {
  cursor: pointer;
  text-decoration: underline;
}
#schedule{
  width: max-content;
}
@media (max-width: 1020px) {
  #programmes h2 {
    font-size: 24px;
    line-height: 30px;
  }
  #programmes .details {
    font-size: 16px;
  }
  .event-time,
  .event-details {
    font-size: 22px;
  }

  .event-details {
    max-width: 360px;
  }
}
@media (max-width: 800px) {
  .event-details {
    max-width: 300px;
  }  
}
@media (max-width: 767px) {
  #event-date{
    max-width: 205px;
    height: auto;
  }
  #programmes{
    padding-top:100px;
  }
  #schedule{
    width: auto;
  }  
  .event-time, .event-details{
    max-width: none;
    width: auto;
    text-align: center;
  }
}
