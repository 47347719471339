#footer-cont {
  background: url("/public/assets/images/bg-footer.png") no-repeat 0 0;
  padding-top:90px;
  background-position: top;
  top:-85px;
  padding-bottom: 3px;
  background-size: cover;
}
#contact-us{
  width: max-content;
  margin:auto;
}
#footer-cont h2 {
  color: #0404d9;
  font-size: 40px;
  font-weight: 700;
}

#footer-cont p {
    color:#fff;
}

#footer-cont p.copyright {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);    
}
.scroll-top{
  bottom:36px;
  z-index: 90;
}
@media (max-width: 1020px) {
  #contact-us{
    max-width: 100vmin;
  }  
}
@media (max-width: 800px) {
  #contact-us{
    max-width: 600px;
  }  
}
@media (max-width: 767px) {
  #social-link {
    width: max-content;
  }
  #footer-cont {
    padding-bottom: 20px;
  }
  #footer-cont h2 {
    font-size: 24px;
  }  
  #contact-us{
    width: 100%;
    max-width: none;
    margin:auto;
  }  
}
